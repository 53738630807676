import Link from 'next/link'
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  PopoverBackdrop,
} from '@headlessui/react'
import { LogoBlack, MenuIcon, MenuClose } from '@ui/components/Svg'
import { Container } from '@ui/components/Container'
import { MobileNavLinks } from '@ui/components/links/MobileNavLinks'
import { DesktopNavLinks } from '@ui/components/links/DesktopNavLinks'

export function Header({
  className,
  isLoggedIn = false,
  trackEvent,
}: {
  className?: string
  isLoggedIn?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackEvent?: (event: string, data?: Record<string, any>) => void
}) {
  return (
    <header className={className}>
      <Container className="relative flex h-[112px] justify-between">
        <div className="z-20 flex w-full items-center justify-between">
          <Link href="/">
            <span className="sr-only">ATM.com</span>
            <LogoBlack className="h-12 w-[162px]" />
          </Link>
          <nav className="hidden lg:block">
            <DesktopNavLinks isLoggedIn={isLoggedIn} />
          </nav>
        </div>
        <nav className="z-10 flex items-center gap-6">
          <Popover className="lg:hidden">
            {({ open }) => (
              <>
                <PopoverButton
                  className="ui-not-focus-visible:outline-none stroke-blueberry-900 relative z-20 -m-2 inline-flex items-center rounded-2xl p-2 hover:bg-zinc-200/50 hover:stroke-gray-600 active:stroke-gray-900"
                  aria-label="Toggle site navigation"
                >
                  {open ? (
                    <MenuClose className="h-6 w-6" />
                  ) : (
                    <MenuIcon className="h-6 w-6" />
                  )}
                </PopoverButton>

                <PopoverBackdrop
                  transition
                  className="bg-blueberry-100/50 fixed inset-0 backdrop-blur transition-opacity duration-300 data-[closed]:opacity-0"
                />

                {open && (
                  <PopoverPanel
                    static
                    transition
                    className="shadow-blueberry-900/20 absolute inset-x-0 top-0 w-auto origin-top rounded-xl bg-white px-6 pb-8 pt-28 shadow-2xl transition duration-300 ease-out data-[closed]:-translate-y-4 data-[closed]:opacity-0"
                  >
                    <MobileNavLinks isLoggedIn={isLoggedIn} />
                  </PopoverPanel>
                )}
              </>
            )}
          </Popover>
        </nav>
      </Container>
    </header>
  )
}
