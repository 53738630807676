import Link from 'next/link'
import {
  PopoverButton,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Button } from '@ui/components/buttons/Button'
import { COMPARISON_LINKS, FEATURES_LINKS } from '@ui/constants'

function MobileNavLink(
  props: Omit<
    React.ComponentPropsWithoutRef<typeof PopoverButton<typeof Link>>,
    'as' | 'className'
  >,
) {
  return (
    <PopoverButton
      as={Link}
      className="h2 hover:text-blueberry-700 block whitespace-nowrap font-extrabold"
      {...props}
    />
  )
}

function MobileDisclosureNavLink(
  props: Omit<
    React.ComponentPropsWithoutRef<typeof PopoverButton<typeof Link>>,
    'as' | 'className'
  >,
) {
  return (
    <PopoverButton
      as={Link}
      className="hover:text-blueberry-700 block font-semibold"
      {...props}
    />
  )
}

function NavButton(
  props: Omit<
    React.ComponentPropsWithoutRef<typeof PopoverButton<typeof Button>>,
    'as' | 'className'
  >,
) {
  return <PopoverButton as={Button} {...props} />
}

function Dropdown({
  title,
  links,
}: {
  title: string
  links: {
    name: string
    href: string
  }[]
}) {
  return (
    <Disclosure as="div">
      <DisclosureButton className="h2 hover:text-blueberry-700 group flex w-full items-center justify-between font-extrabold">
        {title}
        <ChevronDownIcon
          aria-hidden="true"
          className="h-5 w-5 flex-none transition-transform group-data-[open]:rotate-180"
        />
      </DisclosureButton>
      <DisclosurePanel
        transition
        className="mt-4 origin-top space-y-4 pl-4 transition duration-300 ease-out data-[closed]:-translate-y-4 data-[closed]:opacity-0"
      >
        {links.map((item) => (
          <MobileDisclosureNavLink key={item.name} href={item.href}>
            {item.name}
          </MobileDisclosureNavLink>
        ))}
      </DisclosurePanel>
    </Disclosure>
  )
}

function FeaturesDropdown() {
  return <Dropdown title="Features" links={FEATURES_LINKS} />
}

function ComparisonDropdown() {
  return <Dropdown title="Comparisons" links={COMPARISON_LINKS} />
}

export function MobileNavLinks({
  isLoggedIn = false,
}: {
  isLoggedIn?: boolean
}) {
  return (
    <>
      <div className="space-y-6">
        <MobileNavLink href="/">Home</MobileNavLink>
        <MobileNavLink href="/about/">About</MobileNavLink>
        <FeaturesDropdown />
        <ComparisonDropdown />
        <MobileNavLink href="/press/">Press</MobileNavLink>
      </div>
      {isLoggedIn ? (
        <div className="mt-8 flex flex-col gap-4">
          <NavButton href="/auth/logout" variant="white">
            Log out
          </NavButton>
        </div>
      ) : (
        <div className="mt-8 flex flex-col gap-4">
          <NavButton href="/sign-up" variant="whiteSm">
            Sign Up
          </NavButton>
          <NavButton href="/download/" variant="blueberrySm">
            Download
          </NavButton>
        </div>
      )}
    </>
  )
}
