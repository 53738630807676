export const APP_STORE_LINK =
  'https://apps.apple.com/us/app/atm-instant-cash-rewards/id1507829573'
export const GOOGLE_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=com.atm.atm.prod'
export const CUSTOMER_SERVICE_LINK =
  'https://atm.atlassian.net/servicedesk/customer/portals'
export const FEATURES_LINKS = [
  {
    name: 'Budgeting',
    description: 'Budget Smarter',
    href: '/#budgeting',
  },
  {
    name: 'Cash Advance',
    description: 'Get Cash When You Need It',
    href: '/#cash-advance',
  },
  {
    name: 'Personal Finance',
    description: 'Track Your Spending',
    href: '/#personal-finance',
  },
  {
    name: 'Rewards & Cashback',
    description: 'Earn Money',
    href: '/#rewards',
  },
]

export const COMPARISON_LINKS = [
  {
    name: 'Bank Accounts',
    short: 'Banking',
    href: '/compare/bank-account',
    title: 'Compare Bank Accounts',
    description:
      'Explore top bank account offers with no monthly fees, early direct deposit, and cash back rewards.',
    button: 'Compare Banks',
  },
  {
    name: 'Budgeting Apps',
    short: 'Budgeting',
    href: '/compare/budgeting',
    title: 'Compare Budgeting Apps',
    description:
      'Simplify your finances with apps that track spending, manage bills, and help you save smarter.',
    button: 'Compare Budgeting',
  },
  {
    name: 'Cash Advances',
    short: 'Cash Advance',
    href: '/compare/cash-advance',
    title: 'Compare Cash Advances',
    description:
      'Access paycheck advances without interest or credit checks to cover emergency expenses.',
    button: 'Compare Cash Advances',
  },
  {
    name: 'Credit Builder Accounts',
    short: 'Build Credit',
    href: '/compare/credit-builder-account',
    title: 'Compare Credit Builders',
    description:
      'Improve your credit score while building savings with flexible credit builder accounts.',
    button: 'Compare Credit Builders',
  },
  {
    name: 'Investing Apps',
    short: 'Investing',
    href: '/compare/investing',
    title: 'Compare Investing Apps',
    description:
      'Grow your wealth with beginner-friendly investing tools or advanced trading platforms.',
    button: 'Compare Investing',
  },
]
